<template>
  <ul v-if="entries" class="menu" :class="{ 'menu--shown': show }">
    <li
      v-for="entry in entries"
      :key="entry.id"
      v-click-outside="onClickOutide(entry)"
      :class="{ menu__entry: true, 'menu__entry--opened': opened === entry.id }"
      @click="onTopLevelClick(entry)"
    >
      <nuxt-link
        v-slot="{ href, navigate }"
        :to="categoryPath(entry.slug)"
        :data-slug="entry.slug"
        custom
        class="menu__entry__link"
      >
        <a
          :href="href"
          role="link"
          @click="onNavigate(navigate, entry.id, $event)"
          @keypress.enter="onNavigate(navigate, entry.id, $event)"
        >
          {{ entry.name }}
        </a>
      </nuxt-link>
      <transition name="fade" mode="in-out">
        <div v-if="entry.id === opened" class="menu__sub-menu">
          <masonry :cols="subMenuCols" :gutter="20">
            <div
              v-for="subEntry in entry.children"
              :key="subEntry.id"
              class="menu__sub-entry"
            >
              <nuxt-link
                :to="categoryPath(subEntry.slug)"
                class="menu__sub-entry__link"
              >
                <CategoryIcon
                  v-if="subEntry.picto"
                  :icon="subEntry.picto"
                  fill="#F4A650"
                />
                {{ subEntry.name }}
              </nuxt-link>
              <ul class="menu__sub-sub-menu">
                <li
                  v-for="subSubEntry in subEntry.children"
                  :key="subSubEntry.id"
                  class="menu__sub-sub-entry"
                >
                  <nuxt-link
                    :to="categoryPath(subSubEntry.slug)"
                    class="menu__sub-sub-entry__link"
                  >
                    {{ subSubEntry.name }}
                  </nuxt-link>
                </li>
              </ul>
            </div>
          </masonry>
        </div>
      </transition>
    </li>
    <!-- <li class="menu__entry">
      <nuxt-link :to="localePath('HowItWorks')" class="menu__entry__link">
        {{ $t('header.menu.how-it-works') }}
      </nuxt-link>
    </li> -->
  </ul>
</template>

<script>
import CategoryIcon from '@/components/CategoryIcon/CategoryIcon';
import ClickOutside from 'vue-click-outside';

const SUB_MENU_COLS_MAPPING = {
  sm: 1,
  md: 2,
  lg: 2,
  xl: 3
};
export default {
  name: 'Menu',
  directives: {
    ClickOutside
  },
  components: { CategoryIcon },
  props: {
    entries: {
      type: Array,
      default: () => null
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    opened: null,
    preventClickOutside: false
  }),
  computed: {
    subMenuCols() {
      return SUB_MENU_COLS_MAPPING[this.$mq] || 4;
    }
  },
  methods: {
    categoryPath(slug) {
      return this.localePath({
        name: 'Category',
        params: { slug }
      });
    },
    onClickOutide(entry) {
      return () => {
        if (this.preventClickOutside) return;
        this.opened = null;
      };
    },
    onTopLevelClick(entry) {
      this.preventClickOutside = true;
      if (entry.id !== this.opened) {
        this.opened = entry.id;
      } else {
        this.opened = null;
      }
      setTimeout(() => (this.preventClickOutside = false), 250);
    },
    onNavigate(navigate, entryId, event) {
      if (this.opened === entryId) navigate(event);
      else event.preventDefault();
    }
  }
};
</script>

<style lang="scss" scoped src="./menu.scss"></style>
