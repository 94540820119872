import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e5482648 = () => interopDefault(import('../pages/Welcome/index.vue' /* webpackChunkName: "pages/Welcome/index" */))
const _329278d3 = () => interopDefault(import('../pages/Catalog/index.vue' /* webpackChunkName: "pages/Catalog/index" */))
const _74214fc3 = () => interopDefault(import('../pages/HowItWorks/index.vue' /* webpackChunkName: "pages/HowItWorks/index" */))
const _4a580c84 = () => interopDefault(import('../pages/TermsAndConditions/index.vue' /* webpackChunkName: "pages/TermsAndConditions/index" */))
const _50860e69 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1d03601e = () => interopDefault(import('../pages/Impressum/index.vue' /* webpackChunkName: "pages/Impressum/index" */))
const _0e1292a8 = () => interopDefault(import('../pages/Auth/Logout.vue' /* webpackChunkName: "pages/Auth/Logout" */))
const _7791e714 = () => interopDefault(import('../pages/PrivacyPolicy/index.vue' /* webpackChunkName: "pages/PrivacyPolicy/index" */))
const _b1872efe = () => interopDefault(import('../pages/ListPage/Search.vue' /* webpackChunkName: "pages/ListPage/Search" */))
const _70ce7284 = () => interopDefault(import('../pages/Account/SearchHistory/index.vue' /* webpackChunkName: "pages/Account/SearchHistory/index" */))
const _274a77be = () => interopDefault(import('../pages/Account/Inbox/index.vue' /* webpackChunkName: "pages/Account/Inbox/index" */))
const _f44df328 = () => interopDefault(import('../pages/Account/UserSettings/index.vue' /* webpackChunkName: "pages/Account/UserSettings/index" */))
const _92b4c2ce = () => interopDefault(import('../pages/Auth/PasswordResetRequest.vue' /* webpackChunkName: "pages/Auth/PasswordResetRequest" */))
const _7babe640 = () => interopDefault(import('../pages/Auth/SignIn.vue' /* webpackChunkName: "pages/Auth/SignIn" */))
const _527f3954 = () => interopDefault(import('../pages/Auth/SignUp.vue' /* webpackChunkName: "pages/Auth/SignUp" */))
const _202073f2 = () => interopDefault(import('../pages/Auth/SocialRedirect.vue' /* webpackChunkName: "pages/Auth/SocialRedirect" */))
const _37405fe3 = () => interopDefault(import('../pages/Checkout/Cart/index.vue' /* webpackChunkName: "pages/Checkout/Cart/index" */))
const _759d3cf8 = () => interopDefault(import('../pages/Checkout/Cart/old.vue' /* webpackChunkName: "pages/Checkout/Cart/old" */))
const _68e9da32 = () => interopDefault(import('../pages/ListPage/RecoPopularForYou.vue' /* webpackChunkName: "pages/ListPage/RecoPopularForYou" */))
const _bac7d86a = () => interopDefault(import('../pages/ListPage/RecentlyViewedItems.vue' /* webpackChunkName: "pages/ListPage/RecentlyViewedItems" */))
const _b3ec53c4 = () => interopDefault(import('../pages/ListPage/LatestPublished.vue' /* webpackChunkName: "pages/ListPage/LatestPublished" */))
const _871f67ae = () => interopDefault(import('../pages/ListPage/RecoNewsFeed.vue' /* webpackChunkName: "pages/ListPage/RecoNewsFeed" */))
const _1d82a9ca = () => interopDefault(import('../pages/ListPage/Highlighted.vue' /* webpackChunkName: "pages/ListPage/Highlighted" */))
const _97c4bffa = () => interopDefault(import('../pages/ListPage/UserFavorites.vue' /* webpackChunkName: "pages/ListPage/UserFavorites" */))
const _6a85a5a4 = () => interopDefault(import('../pages/ListPage/RecoForYou.vue' /* webpackChunkName: "pages/ListPage/RecoForYou" */))
const _4081423c = () => interopDefault(import('../pages/Member/components/Dressing/Dressing.vue' /* webpackChunkName: "pages/Member/components/Dressing/Dressing" */))
const _1d46ccb0 = () => interopDefault(import('../pages/Member/components/Ratings/Ratings.vue' /* webpackChunkName: "pages/Member/components/Ratings/Ratings" */))
const _3308a83c = () => interopDefault(import('../pages/Member/components/UserReport/UserReport.vue' /* webpackChunkName: "pages/Member/components/UserReport/UserReport" */))
const _f79ba468 = () => interopDefault(import('../pages/Account/UserSettings/components/Bank/Bank.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Bank/Bank" */))
const _207dfa5a = () => interopDefault(import('../pages/Account/UserSettings/components/BrandList/Brand.vue' /* webpackChunkName: "pages/Account/UserSettings/components/BrandList/Brand" */))
const _47d041d0 = () => interopDefault(import('../pages/Account/UserSettings/components/BrandList/BrandList.vue' /* webpackChunkName: "pages/Account/UserSettings/components/BrandList/BrandList" */))
const _615d8aa8 = () => interopDefault(import('../pages/Account/UserSettings/components/BundleDiscount/BundleDiscount.vue' /* webpackChunkName: "pages/Account/UserSettings/components/BundleDiscount/BundleDiscount" */))
const _11afe178 = () => interopDefault(import('../pages/Account/UserSettings/components/Customization/Color.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Customization/Color" */))
const _618af6b4 = () => interopDefault(import('../pages/Account/UserSettings/components/Customization/Customization.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Customization/Customization" */))
const _0999122c = () => interopDefault(import('../pages/Account/UserSettings/components/Holidays/Holidays.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Holidays/Holidays" */))
const _8f6988a8 = () => interopDefault(import('../pages/Account/UserSettings/components/Menu/Menu.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Menu/Menu" */))
const _7cef4c8c = () => interopDefault(import('../pages/Account/UserSettings/components/MyAddresses/MyAddresses.vue' /* webpackChunkName: "pages/Account/UserSettings/components/MyAddresses/MyAddresses" */))
const _0567419a = () => interopDefault(import('../pages/Account/UserSettings/components/MyPurchases/MyPurchases.vue' /* webpackChunkName: "pages/Account/UserSettings/components/MyPurchases/MyPurchases" */))
const _963715e4 = () => interopDefault(import('../pages/Account/UserSettings/components/MySales/MySales.vue' /* webpackChunkName: "pages/Account/UserSettings/components/MySales/MySales" */))
const _09c135a8 = () => interopDefault(import('../pages/Account/UserSettings/components/Notification/Notification.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Notification/Notification" */))
const _3b0c3b2c = () => interopDefault(import('../pages/Account/UserSettings/components/Settings/Settings.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Settings/Settings" */))
const _b376c428 = () => interopDefault(import('../pages/Account/UserSettings/components/TaxonCustomization/TaxonCustomization.vue' /* webpackChunkName: "pages/Account/UserSettings/components/TaxonCustomization/TaxonCustomization" */))
const _6830ebec = () => interopDefault(import('../pages/Account/UserSettings/components/Wallet/Wallet.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Wallet/Wallet" */))
const _1b6bda81 = () => interopDefault(import('../pages/Checkout/Cart/components/PaymentSelector/PaymentSelector.vue' /* webpackChunkName: "pages/Checkout/Cart/components/PaymentSelector/PaymentSelector" */))
const _4a52347c = () => interopDefault(import('../pages/Account/UserSettings/components/MyAddresses/components/PostalAddress.vue' /* webpackChunkName: "pages/Account/UserSettings/components/MyAddresses/components/PostalAddress" */))
const _351e7d09 = () => interopDefault(import('../pages/Account/UserSettings/components/Settings/components/AvatarModal/AvatarModal.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Settings/components/AvatarModal/AvatarModal" */))
const _112c44ff = () => interopDefault(import('../pages/Account/UserSettings/components/Settings/components/EmailModal/EmailModal.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Settings/components/EmailModal/EmailModal" */))
const _08a3b945 = () => interopDefault(import('../pages/Account/UserSettings/components/Settings/components/PasswordModal/PasswordModal.vue' /* webpackChunkName: "pages/Account/UserSettings/components/Settings/components/PasswordModal/PasswordModal" */))
const _3b009c74 = () => interopDefault(import('../pages/Auth/PasswordReset.vue' /* webpackChunkName: "pages/Auth/PasswordReset" */))
const _528c9de7 = () => interopDefault(import('../pages/Account/Inbox/Conversation.vue' /* webpackChunkName: "pages/Account/Inbox/Conversation" */))
const _647f8183 = () => interopDefault(import('../pages/Checkout/Payment/Cancel.vue' /* webpackChunkName: "pages/Checkout/Payment/Cancel" */))
const _073a4fd1 = () => interopDefault(import('../pages/Checkout/Payment/Failure.vue' /* webpackChunkName: "pages/Checkout/Payment/Failure" */))
const _402579ca = () => interopDefault(import('../pages/Checkout/Payment/Success.vue' /* webpackChunkName: "pages/Checkout/Payment/Success" */))
const _cc13abd6 = () => interopDefault(import('../pages/ListPage/RecoBrandForYou.vue' /* webpackChunkName: "pages/ListPage/RecoBrandForYou" */))
const _040a952c = () => interopDefault(import('../pages/AddProduct/index.vue' /* webpackChunkName: "pages/AddProduct/index" */))
const _07f644cc = () => interopDefault(import('../pages/Category/index.vue' /* webpackChunkName: "pages/Category/index" */))
const _97f9b714 = () => interopDefault(import('../pages/Member/index.vue' /* webpackChunkName: "pages/Member/index" */))
const _be5c634e = () => interopDefault(import('../pages/ProductCard/index.vue' /* webpackChunkName: "pages/ProductCard/index" */))
const _2e5ba296 = () => interopDefault(import('../pages/ListPage/SearchOld.vue' /* webpackChunkName: "pages/ListPage/SearchOld" */))
const _4bdfaa40 = () => interopDefault(import('../pages/Bundle/Create.vue' /* webpackChunkName: "pages/Bundle/Create" */))
const _69620d26 = () => interopDefault(import('../pages/Account/Verified/index.vue' /* webpackChunkName: "pages/Account/Verified/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bienvenue",
    component: _e5482648,
    name: "Welcome___fr"
  }, {
    path: "/catalog",
    component: _329278d3,
    name: "Catalog___fr"
  }, {
    path: "/comment-ca-marche",
    component: _74214fc3,
    name: "HowItWorks___fr"
  }, {
    path: "/conditions-generales",
    component: _4a580c84,
    name: "TermsAndConditions___fr"
  }, {
    path: "/de",
    component: _50860e69,
    name: "index___de"
  }, {
    path: "/en",
    component: _50860e69,
    name: "index___en"
  }, {
    path: "/impressum",
    component: _1d03601e,
    name: "Impressum___fr"
  }, {
    path: "/logout",
    component: _0e1292a8,
    name: "Auth-Logout___fr"
  }, {
    path: "/politique-de-confidentialite",
    component: _7791e714,
    name: "PrivacyPolicy___fr"
  }, {
    path: "/search",
    component: _b1872efe,
    name: "ListPage-Search___fr"
  }, {
    path: "/account/historique-de-recherche",
    component: _70ce7284,
    name: "Account-SearchHistory___fr"
  }, {
    path: "/account/inbox",
    component: _274a77be,
    name: "Account-Inbox___fr"
  }, {
    path: "/account/user-settings",
    component: _f44df328,
    name: "Account-UserSettings___fr"
  }, {
    path: "/auth/reset-password",
    component: _92b4c2ce,
    name: "Auth-PasswordResetRequest___fr"
  }, {
    path: "/auth/signin",
    component: _7babe640,
    name: "Auth-SignIn___fr"
  }, {
    path: "/auth/signup",
    component: _527f3954,
    name: "Auth-SignUp___fr"
  }, {
    path: "/auth/social-redirect",
    component: _202073f2,
    name: "Auth-SocialRedirect___fr"
  }, {
    path: "/checkout/cart",
    component: _37405fe3,
    name: "Checkout-Cart___fr"
  }, {
    path: "/checkout/cart-old",
    component: _759d3cf8,
    name: "Checkout-Cart-old___fr"
  }, {
    path: "/de/allgemeine-geschaftsbedingungen",
    component: _4a580c84,
    name: "TermsAndConditions___de"
  }, {
    path: "/de/catalog",
    component: _329278d3,
    name: "Catalog___de"
  }, {
    path: "/de/datenschutzrichtlinie-der-clozen-plattform",
    component: _7791e714,
    name: "PrivacyPolicy___de"
  }, {
    path: "/de/impressum",
    component: _1d03601e,
    name: "Impressum___de"
  }, {
    path: "/de/logout",
    component: _0e1292a8,
    name: "Auth-Logout___de"
  }, {
    path: "/de/search",
    component: _b1872efe,
    name: "ListPage-Search___de"
  }, {
    path: "/de/wie-es-funktioniert",
    component: _74214fc3,
    name: "HowItWorks___de"
  }, {
    path: "/de/willkommen",
    component: _e5482648,
    name: "Welcome___de"
  }, {
    path: "/en/catalog",
    component: _329278d3,
    name: "Catalog___en"
  }, {
    path: "/en/how-it-works",
    component: _74214fc3,
    name: "HowItWorks___en"
  }, {
    path: "/en/imprint",
    component: _1d03601e,
    name: "Impressum___en"
  }, {
    path: "/en/logout",
    component: _0e1292a8,
    name: "Auth-Logout___en"
  }, {
    path: "/en/privacy-policy",
    component: _7791e714,
    name: "PrivacyPolicy___en"
  }, {
    path: "/en/search",
    component: _b1872efe,
    name: "ListPage-Search___en"
  }, {
    path: "/en/terms-and-conditions",
    component: _4a580c84,
    name: "TermsAndConditions___en"
  }, {
    path: "/en/welcome",
    component: _e5482648,
    name: "Welcome___en"
  }, {
    path: "/list/articles-populaires",
    component: _68e9da32,
    name: "ListPage-RecoPopularForYou___fr"
  }, {
    path: "/list/articles-recemment-consultes",
    component: _bac7d86a,
    name: "ListPage-RecentlyViewedItems___fr"
  }, {
    path: "/list/dernieres-publications",
    component: _b3ec53c4,
    name: "ListPage-LatestPublished___fr"
  }, {
    path: "/list/fil-d-actu",
    component: _871f67ae,
    name: "ListPage-RecoNewsFeed___fr"
  }, {
    path: "/list/highlighted",
    component: _1d82a9ca,
    name: "ListPage-Highlighted___fr"
  }, {
    path: "/list/mes-favoris",
    component: _97c4bffa,
    name: "ListPage-UserFavorites___fr"
  }, {
    path: "/list/recommande-pour-toi",
    component: _6a85a5a4,
    name: "ListPage-RecoForYou___fr"
  }, {
    path: "/de/account/inbox",
    component: _274a77be,
    name: "Account-Inbox___de"
  }, {
    path: "/de/account/suchverlauf",
    component: _70ce7284,
    name: "Account-SearchHistory___de"
  }, {
    path: "/de/account/user-settings",
    component: _f44df328,
    name: "Account-UserSettings___de"
  }, {
    path: "/de/auth/reset-password",
    component: _92b4c2ce,
    name: "Auth-PasswordResetRequest___de"
  }, {
    path: "/de/auth/signin",
    component: _7babe640,
    name: "Auth-SignIn___de"
  }, {
    path: "/de/auth/signup",
    component: _527f3954,
    name: "Auth-SignUp___de"
  }, {
    path: "/de/auth/social-redirect",
    component: _202073f2,
    name: "Auth-SocialRedirect___de"
  }, {
    path: "/de/checkout/cart",
    component: _37405fe3,
    name: "Checkout-Cart___de"
  }, {
    path: "/de/checkout/cart-old",
    component: _759d3cf8,
    name: "Checkout-Cart-old___de"
  }, {
    path: "/de/list/beliebte-artikel",
    component: _68e9da32,
    name: "ListPage-RecoPopularForYou___de"
  }, {
    path: "/de/list/empfohlen-fur-dich",
    component: _6a85a5a4,
    name: "ListPage-RecoForYou___de"
  }, {
    path: "/de/list/highlighted",
    component: _1d82a9ca,
    name: "ListPage-Highlighted___de"
  }, {
    path: "/de/list/letzte-veroffentlichungen",
    component: _b3ec53c4,
    name: "ListPage-LatestPublished___de"
  }, {
    path: "/de/list/meine-lieblingsartikel",
    component: _97c4bffa,
    name: "ListPage-UserFavorites___de"
  }, {
    path: "/de/list/news-feed",
    component: _871f67ae,
    name: "ListPage-RecoNewsFeed___de"
  }, {
    path: "/de/list/zuletzt-angesehenen-artikel",
    component: _bac7d86a,
    name: "ListPage-RecentlyViewedItems___de"
  }, {
    path: "/en/account/inbox",
    component: _274a77be,
    name: "Account-Inbox___en"
  }, {
    path: "/en/account/search-history",
    component: _70ce7284,
    name: "Account-SearchHistory___en"
  }, {
    path: "/en/account/user-settings",
    component: _f44df328,
    name: "Account-UserSettings___en"
  }, {
    path: "/en/auth/reset-password",
    component: _92b4c2ce,
    name: "Auth-PasswordResetRequest___en"
  }, {
    path: "/en/auth/signin",
    component: _7babe640,
    name: "Auth-SignIn___en"
  }, {
    path: "/en/auth/signup",
    component: _527f3954,
    name: "Auth-SignUp___en"
  }, {
    path: "/en/auth/social-redirect",
    component: _202073f2,
    name: "Auth-SocialRedirect___en"
  }, {
    path: "/en/checkout/cart",
    component: _37405fe3,
    name: "Checkout-Cart___en"
  }, {
    path: "/en/checkout/cart-old",
    component: _759d3cf8,
    name: "Checkout-Cart-old___en"
  }, {
    path: "/en/list/highlighted",
    component: _1d82a9ca,
    name: "ListPage-Highlighted___en"
  }, {
    path: "/en/list/latest-publications",
    component: _b3ec53c4,
    name: "ListPage-LatestPublished___en"
  }, {
    path: "/en/list/my-favorites",
    component: _97c4bffa,
    name: "ListPage-UserFavorites___en"
  }, {
    path: "/en/list/news-feed",
    component: _871f67ae,
    name: "ListPage-RecoNewsFeed___en"
  }, {
    path: "/en/list/popular-articles",
    component: _68e9da32,
    name: "ListPage-RecoPopularForYou___en"
  }, {
    path: "/en/list/recently-viewed-items",
    component: _bac7d86a,
    name: "ListPage-RecentlyViewedItems___en"
  }, {
    path: "/en/list/recommended-for-you",
    component: _6a85a5a4,
    name: "ListPage-RecoForYou___en"
  }, {
    path: "/Member/components/Dressing/Dressing",
    component: _4081423c,
    name: "Member-components-Dressing-Dressing___fr"
  }, {
    path: "/Member/components/Ratings/Ratings",
    component: _1d46ccb0,
    name: "Member-components-Ratings-Ratings___fr"
  }, {
    path: "/Member/components/UserReport/UserReport",
    component: _3308a83c,
    name: "Member-components-UserReport-UserReport___fr"
  }, {
    path: "/Account/UserSettings/components/Bank/Bank",
    component: _f79ba468,
    name: "Account-UserSettings-components-Bank-Bank___fr"
  }, {
    path: "/Account/UserSettings/components/BrandList/Brand",
    component: _207dfa5a,
    name: "Account-UserSettings-components-BrandList-Brand___fr"
  }, {
    path: "/Account/UserSettings/components/BrandList/BrandList",
    component: _47d041d0,
    name: "Account-UserSettings-components-BrandList-BrandList___fr"
  }, {
    path: "/Account/UserSettings/components/BundleDiscount/BundleDiscount",
    component: _615d8aa8,
    name: "Account-UserSettings-components-BundleDiscount-BundleDiscount___fr"
  }, {
    path: "/Account/UserSettings/components/Customization/Color",
    component: _11afe178,
    name: "Account-UserSettings-components-Customization-Color___fr"
  }, {
    path: "/Account/UserSettings/components/Customization/Customization",
    component: _618af6b4,
    name: "Account-UserSettings-components-Customization-Customization___fr"
  }, {
    path: "/Account/UserSettings/components/Holidays/Holidays",
    component: _0999122c,
    name: "Account-UserSettings-components-Holidays-Holidays___fr"
  }, {
    path: "/Account/UserSettings/components/Menu/Menu",
    component: _8f6988a8,
    name: "Account-UserSettings-components-Menu-Menu___fr"
  }, {
    path: "/Account/UserSettings/components/MyAddresses/MyAddresses",
    component: _7cef4c8c,
    name: "Account-UserSettings-components-MyAddresses-MyAddresses___fr"
  }, {
    path: "/Account/UserSettings/components/MyPurchases/MyPurchases",
    component: _0567419a,
    name: "Account-UserSettings-components-MyPurchases-MyPurchases___fr"
  }, {
    path: "/Account/UserSettings/components/MySales/MySales",
    component: _963715e4,
    name: "Account-UserSettings-components-MySales-MySales___fr"
  }, {
    path: "/Account/UserSettings/components/Notification/Notification",
    component: _09c135a8,
    name: "Account-UserSettings-components-Notification-Notification___fr"
  }, {
    path: "/Account/UserSettings/components/Settings/Settings",
    component: _3b0c3b2c,
    name: "Account-UserSettings-components-Settings-Settings___fr"
  }, {
    path: "/Account/UserSettings/components/TaxonCustomization/TaxonCustomization",
    component: _b376c428,
    name: "Account-UserSettings-components-TaxonCustomization-TaxonCustomization___fr"
  }, {
    path: "/Account/UserSettings/components/Wallet/Wallet",
    component: _6830ebec,
    name: "Account-UserSettings-components-Wallet-Wallet___fr"
  }, {
    path: "/Checkout/Cart/components/PaymentSelector/PaymentSelector",
    component: _1b6bda81,
    name: "Checkout-Cart-components-PaymentSelector-PaymentSelector___fr"
  }, {
    path: "/de/Member/components/Dressing/Dressing",
    component: _4081423c,
    name: "Member-components-Dressing-Dressing___de"
  }, {
    path: "/de/Member/components/Ratings/Ratings",
    component: _1d46ccb0,
    name: "Member-components-Ratings-Ratings___de"
  }, {
    path: "/de/Member/components/UserReport/UserReport",
    component: _3308a83c,
    name: "Member-components-UserReport-UserReport___de"
  }, {
    path: "/en/Member/components/Dressing/Dressing",
    component: _4081423c,
    name: "Member-components-Dressing-Dressing___en"
  }, {
    path: "/en/Member/components/Ratings/Ratings",
    component: _1d46ccb0,
    name: "Member-components-Ratings-Ratings___en"
  }, {
    path: "/en/Member/components/UserReport/UserReport",
    component: _3308a83c,
    name: "Member-components-UserReport-UserReport___en"
  }, {
    path: "/Account/UserSettings/components/MyAddresses/components/PostalAddress",
    component: _4a52347c,
    name: "Account-UserSettings-components-MyAddresses-components-PostalAddress___fr"
  }, {
    path: "/de/Account/UserSettings/components/Bank/Bank",
    component: _f79ba468,
    name: "Account-UserSettings-components-Bank-Bank___de"
  }, {
    path: "/de/Account/UserSettings/components/BrandList/Brand",
    component: _207dfa5a,
    name: "Account-UserSettings-components-BrandList-Brand___de"
  }, {
    path: "/de/Account/UserSettings/components/BrandList/BrandList",
    component: _47d041d0,
    name: "Account-UserSettings-components-BrandList-BrandList___de"
  }, {
    path: "/de/Account/UserSettings/components/BundleDiscount/BundleDiscount",
    component: _615d8aa8,
    name: "Account-UserSettings-components-BundleDiscount-BundleDiscount___de"
  }, {
    path: "/de/Account/UserSettings/components/Customization/Color",
    component: _11afe178,
    name: "Account-UserSettings-components-Customization-Color___de"
  }, {
    path: "/de/Account/UserSettings/components/Customization/Customization",
    component: _618af6b4,
    name: "Account-UserSettings-components-Customization-Customization___de"
  }, {
    path: "/de/Account/UserSettings/components/Holidays/Holidays",
    component: _0999122c,
    name: "Account-UserSettings-components-Holidays-Holidays___de"
  }, {
    path: "/de/Account/UserSettings/components/Menu/Menu",
    component: _8f6988a8,
    name: "Account-UserSettings-components-Menu-Menu___de"
  }, {
    path: "/de/Account/UserSettings/components/MyAddresses/MyAddresses",
    component: _7cef4c8c,
    name: "Account-UserSettings-components-MyAddresses-MyAddresses___de"
  }, {
    path: "/de/Account/UserSettings/components/MyPurchases/MyPurchases",
    component: _0567419a,
    name: "Account-UserSettings-components-MyPurchases-MyPurchases___de"
  }, {
    path: "/de/Account/UserSettings/components/MySales/MySales",
    component: _963715e4,
    name: "Account-UserSettings-components-MySales-MySales___de"
  }, {
    path: "/de/Account/UserSettings/components/Notification/Notification",
    component: _09c135a8,
    name: "Account-UserSettings-components-Notification-Notification___de"
  }, {
    path: "/de/Account/UserSettings/components/Settings/Settings",
    component: _3b0c3b2c,
    name: "Account-UserSettings-components-Settings-Settings___de"
  }, {
    path: "/de/Account/UserSettings/components/TaxonCustomization/TaxonCustomization",
    component: _b376c428,
    name: "Account-UserSettings-components-TaxonCustomization-TaxonCustomization___de"
  }, {
    path: "/de/Account/UserSettings/components/Wallet/Wallet",
    component: _6830ebec,
    name: "Account-UserSettings-components-Wallet-Wallet___de"
  }, {
    path: "/de/Checkout/Cart/components/PaymentSelector/PaymentSelector",
    component: _1b6bda81,
    name: "Checkout-Cart-components-PaymentSelector-PaymentSelector___de"
  }, {
    path: "/en/Account/UserSettings/components/Bank/Bank",
    component: _f79ba468,
    name: "Account-UserSettings-components-Bank-Bank___en"
  }, {
    path: "/en/Account/UserSettings/components/BrandList/Brand",
    component: _207dfa5a,
    name: "Account-UserSettings-components-BrandList-Brand___en"
  }, {
    path: "/en/Account/UserSettings/components/BrandList/BrandList",
    component: _47d041d0,
    name: "Account-UserSettings-components-BrandList-BrandList___en"
  }, {
    path: "/en/Account/UserSettings/components/BundleDiscount/BundleDiscount",
    component: _615d8aa8,
    name: "Account-UserSettings-components-BundleDiscount-BundleDiscount___en"
  }, {
    path: "/en/Account/UserSettings/components/Customization/Color",
    component: _11afe178,
    name: "Account-UserSettings-components-Customization-Color___en"
  }, {
    path: "/en/Account/UserSettings/components/Customization/Customization",
    component: _618af6b4,
    name: "Account-UserSettings-components-Customization-Customization___en"
  }, {
    path: "/en/Account/UserSettings/components/Holidays/Holidays",
    component: _0999122c,
    name: "Account-UserSettings-components-Holidays-Holidays___en"
  }, {
    path: "/en/Account/UserSettings/components/Menu/Menu",
    component: _8f6988a8,
    name: "Account-UserSettings-components-Menu-Menu___en"
  }, {
    path: "/en/Account/UserSettings/components/MyAddresses/MyAddresses",
    component: _7cef4c8c,
    name: "Account-UserSettings-components-MyAddresses-MyAddresses___en"
  }, {
    path: "/en/Account/UserSettings/components/MyPurchases/MyPurchases",
    component: _0567419a,
    name: "Account-UserSettings-components-MyPurchases-MyPurchases___en"
  }, {
    path: "/en/Account/UserSettings/components/MySales/MySales",
    component: _963715e4,
    name: "Account-UserSettings-components-MySales-MySales___en"
  }, {
    path: "/en/Account/UserSettings/components/Notification/Notification",
    component: _09c135a8,
    name: "Account-UserSettings-components-Notification-Notification___en"
  }, {
    path: "/en/Account/UserSettings/components/Settings/Settings",
    component: _3b0c3b2c,
    name: "Account-UserSettings-components-Settings-Settings___en"
  }, {
    path: "/en/Account/UserSettings/components/TaxonCustomization/TaxonCustomization",
    component: _b376c428,
    name: "Account-UserSettings-components-TaxonCustomization-TaxonCustomization___en"
  }, {
    path: "/en/Account/UserSettings/components/Wallet/Wallet",
    component: _6830ebec,
    name: "Account-UserSettings-components-Wallet-Wallet___en"
  }, {
    path: "/en/Checkout/Cart/components/PaymentSelector/PaymentSelector",
    component: _1b6bda81,
    name: "Checkout-Cart-components-PaymentSelector-PaymentSelector___en"
  }, {
    path: "/Account/UserSettings/components/Settings/components/AvatarModal/AvatarModal",
    component: _351e7d09,
    name: "Account-UserSettings-components-Settings-components-AvatarModal-AvatarModal___fr"
  }, {
    path: "/Account/UserSettings/components/Settings/components/EmailModal/EmailModal",
    component: _112c44ff,
    name: "Account-UserSettings-components-Settings-components-EmailModal-EmailModal___fr"
  }, {
    path: "/Account/UserSettings/components/Settings/components/PasswordModal/PasswordModal",
    component: _08a3b945,
    name: "Account-UserSettings-components-Settings-components-PasswordModal-PasswordModal___fr"
  }, {
    path: "/de/Account/UserSettings/components/MyAddresses/components/PostalAddress",
    component: _4a52347c,
    name: "Account-UserSettings-components-MyAddresses-components-PostalAddress___de"
  }, {
    path: "/en/Account/UserSettings/components/MyAddresses/components/PostalAddress",
    component: _4a52347c,
    name: "Account-UserSettings-components-MyAddresses-components-PostalAddress___en"
  }, {
    path: "/de/Account/UserSettings/components/Settings/components/AvatarModal/AvatarModal",
    component: _351e7d09,
    name: "Account-UserSettings-components-Settings-components-AvatarModal-AvatarModal___de"
  }, {
    path: "/de/Account/UserSettings/components/Settings/components/EmailModal/EmailModal",
    component: _112c44ff,
    name: "Account-UserSettings-components-Settings-components-EmailModal-EmailModal___de"
  }, {
    path: "/de/Account/UserSettings/components/Settings/components/PasswordModal/PasswordModal",
    component: _08a3b945,
    name: "Account-UserSettings-components-Settings-components-PasswordModal-PasswordModal___de"
  }, {
    path: "/en/Account/UserSettings/components/Settings/components/AvatarModal/AvatarModal",
    component: _351e7d09,
    name: "Account-UserSettings-components-Settings-components-AvatarModal-AvatarModal___en"
  }, {
    path: "/en/Account/UserSettings/components/Settings/components/EmailModal/EmailModal",
    component: _112c44ff,
    name: "Account-UserSettings-components-Settings-components-EmailModal-EmailModal___en"
  }, {
    path: "/en/Account/UserSettings/components/Settings/components/PasswordModal/PasswordModal",
    component: _08a3b945,
    name: "Account-UserSettings-components-Settings-components-PasswordModal-PasswordModal___en"
  }, {
    path: "/de/auth/reset-password/reset/:token",
    component: _3b009c74,
    name: "Auth-PasswordReset___de"
  }, {
    path: "/en/auth/reset-password/reset/:token",
    component: _3b009c74,
    name: "Auth-PasswordReset___en"
  }, {
    path: "/auth/reset-password/reset/:token",
    component: _3b009c74,
    name: "Auth-PasswordReset___fr"
  }, {
    path: "/de/account/inbox/:conversationId",
    component: _528c9de7,
    name: "Account-Inbox-Conversation___de"
  }, {
    path: "/en/account/inbox/:conversationId",
    component: _528c9de7,
    name: "Account-Inbox-Conversation___en"
  }, {
    path: "/de/checkout/payment/:transactionId/cancel",
    component: _647f8183,
    name: "Checkout-Payment-Cancel___de"
  }, {
    path: "/de/checkout/payment/:transactionId/failure",
    component: _073a4fd1,
    name: "Checkout-Payment-Failure___de"
  }, {
    path: "/de/checkout/payment/:transactionId/success",
    component: _402579ca,
    name: "Checkout-Payment-Success___de"
  }, {
    path: "/de/list/marke/:slug/fur-dich",
    component: _cc13abd6,
    name: "ListPage-RecoBrandForYou___de"
  }, {
    path: "/en/checkout/payment/:transactionId/cancel",
    component: _647f8183,
    name: "Checkout-Payment-Cancel___en"
  }, {
    path: "/en/checkout/payment/:transactionId/failure",
    component: _073a4fd1,
    name: "Checkout-Payment-Failure___en"
  }, {
    path: "/en/checkout/payment/:transactionId/success",
    component: _402579ca,
    name: "Checkout-Payment-Success___en"
  }, {
    path: "/en/list/brand/:slug/for-you",
    component: _cc13abd6,
    name: "ListPage-RecoBrandForYou___en"
  }, {
    path: "/account/inbox/:conversationId",
    component: _528c9de7,
    name: "Account-Inbox-Conversation___fr"
  }, {
    path: "/de/artikel-hinzufugen/:id?",
    component: _040a952c,
    name: "AddProduct___de"
  }, {
    path: "/de/category/:slug",
    component: _07f644cc,
    name: "Category___de"
  }, {
    path: "/de/member/:slug",
    component: _97f9b714,
    name: "Member___de"
  }, {
    path: "/de/product/:slug",
    component: _be5c634e,
    name: "ProductCard___de"
  }, {
    path: "/de/search/:query",
    component: _2e5ba296,
    name: "ListPage-SearchOld___de"
  }, {
    path: "/en/add-item/:id?",
    component: _040a952c,
    name: "AddProduct___en"
  }, {
    path: "/en/category/:slug",
    component: _07f644cc,
    name: "Category___en"
  }, {
    path: "/en/member/:slug",
    component: _97f9b714,
    name: "Member___en"
  }, {
    path: "/en/product/:slug",
    component: _be5c634e,
    name: "ProductCard___en"
  }, {
    path: "/en/search/:query",
    component: _2e5ba296,
    name: "ListPage-SearchOld___en"
  }, {
    path: "/checkout/payment/:transactionId/cancel",
    component: _647f8183,
    name: "Checkout-Payment-Cancel___fr"
  }, {
    path: "/checkout/payment/:transactionId/failure",
    component: _073a4fd1,
    name: "Checkout-Payment-Failure___fr"
  }, {
    path: "/checkout/payment/:transactionId/success",
    component: _402579ca,
    name: "Checkout-Payment-Success___fr"
  }, {
    path: "/de/user/:user/create-bundle",
    component: _4bdfaa40,
    name: "Bundle-Create___de"
  }, {
    path: "/en/user/:user/create-bundle",
    component: _4bdfaa40,
    name: "Bundle-Create___en"
  }, {
    path: "/list/marque/:slug/pour-toi",
    component: _cc13abd6,
    name: "ListPage-RecoBrandForYou___fr"
  }, {
    path: "/ajout-article/:id?",
    component: _040a952c,
    name: "AddProduct___fr"
  }, {
    path: "/category/:slug",
    component: _07f644cc,
    name: "Category___fr"
  }, {
    path: "/member/:slug",
    component: _97f9b714,
    name: "Member___fr"
  }, {
    path: "/product/:slug",
    component: _be5c634e,
    name: "ProductCard___fr"
  }, {
    path: "/search/:query",
    component: _2e5ba296,
    name: "ListPage-SearchOld___fr"
  }, {
    path: "/de/:slug/willkommen",
    component: _69620d26,
    name: "Account-Verified___de"
  }, {
    path: "/en/:slug/welcome",
    component: _69620d26,
    name: "Account-Verified___en"
  }, {
    path: "/user/:user/create-bundle",
    component: _4bdfaa40,
    name: "Bundle-Create___fr"
  }, {
    path: "/",
    component: _50860e69,
    name: "index___fr"
  }, {
    path: "/:slug/bienvenue",
    component: _69620d26,
    name: "Account-Verified___fr"
  }],

  parseQuery: function(query) {
      return require('qs').parse(query);
    },
  stringifyQuery: function(query) {
      const res = require('qs').stringify(query);
      return res ? '?' + res : '';
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
