<template>
  <div class="default-layout">
    <Header
      @menu:toggle="menuShown = !menuShown"
      @notifications:reload="reloadNotifications"
    />
    <div v-if="isMdMinus" class="mobile-menu">
      <MenuMobile
        :entries="menuEntries"
        :show="menuShown"
        @menu:close="menuShown = false"
      />
    </div>
    <div class="body">
      <client-only placeholder="">
        <BannerSwibeco v-if="isSwibecoPartner" />
      </client-only>
      <nuxt />
    </div>
    <!-- <Reinsurance v-if="showReinsurance" class="container" /> -->
    <Footer class="container" @notifications:reload="reloadNotifications" />
    <AuthModal v-if="!$auth.loggedIn" />
    <TwoFactorReminderModal ref="twoFactorReminder" />
    <Axeptio />
    <Loader :value="globalLoading" class="global-loader" />
  </div>
</template>

<script>
import Reinsurance from '@/components/Footer/components/Reinsurance/Reinsurance';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import MenuMobile from '@/components/Header/components/Menu/MenuMobile';
import BannerSwibeco from '@/components/Banner/Swibeco';
import AuthModal from '@/components/Authentication/AuthModal';
import TwoFactorReminderModal from '@/components/Modal/TwoFactorReminderModal';
import Axeptio from '@/components/Axeptio/Axeptio';
import Loader from '@/components/Loading/Loader';
import resolutionsMixin from '@/mixins/resolutions';
import { mapState, mapActions } from 'pinia';
import { useMenuStore } from '@/stores/menu';
import { useUserStore } from '@/stores/user';
import { useGlobalStore } from '@/stores/global';
import notificationMixin from '@/mixins/notification';
import isNil from 'lodash.isnil';

export default {
  name: 'DefaultLayout',
  components: {
    Reinsurance,
    Footer,
    Header,
    MenuMobile,
    AuthModal,
    TwoFactorReminderModal,
    Axeptio,
    BannerSwibeco,
    Loader
  },
  mixins: [resolutionsMixin, notificationMixin],
  props: {
    showReinsurance: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    menuShown: false,
    loading: false,
    twoFactorTimeout: null
  }),
  watch: {
    $route() {
      this.menuShown = false;
    }
  },
  computed: {
    ...mapState(useMenuStore, { menuEntries: 'entries' }),
    ...mapState(useUserStore, ['favoriteItemsIds']),
    ...mapState(useGlobalStore, ['globalLoading']),
    isSwibecoPartner() {
      return this.$partner?.isSwibecoPartner() || false;
    }
  },
  mounted() {
    setTimeout(() => this.$auth.loggedIn && this.$auth.fetchUser(), 100);
    this.loadAllFavorites();
    if (
      this.$auth.loggedIn &&
      !this.$auth.user.twoFactorAuthenticationEnabled &&
      !this.$route.name.match(/Account-UserSettings___/)
    ) {
      let shouldDisplay = true;
      const lastClosed = window.localStorage.getItem(
        '2fa:reminder:last-closed'
      );
      if (!isNil(lastClosed)) {
        const threshold = 7 * 24 * 60 * 60 * 1000; // 7 days (in ms)
        Date.now() - lastClosed < threshold && (shouldDisplay = false);
      }
      this.twoFactorTimeout = setTimeout(
        () => shouldDisplay && this.$refs.twoFactorReminder?.show(),
        5000
      );
    }
  },
  methods: {
    ...mapActions(useUserStore, ['fetchFavoriteItemsIds']),
    showGlobalLoader() {
      this.loading = true;
    },
    hideGlobalLoader() {
      this.loading = false;
    },
    async loadAllFavorites() {
      if (!this.$auth.loggedIn) return;
      if (!isNil(this.favoriteItemsIds)) return;
      await this.fetchFavoriteItemsIds();
    }
  }
};
</script>

<style lang="scss" scoped>
.default-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    margin-top: $header-desktop-height;

    @media (max-width: $md) {
      margin-top: $header-mobile-height;
    }
  }

  .global-loader {
    position: fixed;
    z-index: 99;
  }
}
</style>
